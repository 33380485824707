<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_search">
          <div style="display: flex">
            <div>
              <div class="search_center">
                部门：
                <DeptCascader
                  :placeholder="'请选择部门'"
                  :deptList="deptList"
                  v-model="parameter.deptId"
                />
              </div>
              <div class="search_center">
                项目名称：
                <el-autocomplete
                  class="ipt_width project_width"
                  v-model="parameter.projectName"
                  :fetch-suggestions="projectQuerySearch"
                  placeholder="请输入或选择项目名"
                  @select="onProkectNameSelect"
                >
                </el-autocomplete>
              </div>
              <!-- <span
            >项目类型：
            <el-select
              v-model="parameter.projectType"
              placeholder="请选择项目类型"
              clearable
              class="ipt_width">
              <el-option
                v-for="item in ProjectType"
                :label="item.dictName"
                :value="item.dictVal"
                :key="item.dictVal"></el-option>
            </el-select>
          </span> -->

              <div class="search_center">
                <span
                  >业务种类：
                  <el-select
                    class="ipt_width"
                    v-model="parameter.businessLines"
                    placeholder="请选择业务种类"
                    filterable
                    clearable
                  >
                    <el-option
                      v-for="item in dictData.businessLines"
                      :label="item.dictName"
                      :value="item.dictVal"
                      :key="item.dictVal"
                    ></el-option>
                  </el-select>
                </span>
              </div>
              <div class="search_center">
                <span
                  >项目承接公司：
                  <el-select
                    class="ipt_width"
                    v-model="parameter.companyType"
                    placeholder="请选择项目承接公司"
                    filterable
                    clearable
                  >
                    <el-option
                      v-for="item in dictData.companyType"
                      :label="item.dictName"
                      :value="item.dictVal"
                      :key="item.id"
                    ></el-option>
                  </el-select>
                </span>
              </div>
              <div class="search_center">
                业主单位：
                <el-select
                  v-model="parameter.customersUnit"
                  placeholder="请选择业主单位"
                  clearable
                  filterable
                  class="ipt_width"
                >
                  <el-option
                    v-for="item in customersUnit"
                    :label="item"
                    :value="item"
                    :key="item"
                  ></el-option>
                </el-select>
              </div>

              
              <el-button type="primary" icon="el-icon-search" @click="pageChangeHandler(1)"
                >搜索</el-button
              >
              <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
            </div>
            <el-button style="margin-right: 10px; width: 122px;" type="primary" @click="createProject">创建内部项目</el-button>
            <!-- <div>
              <el-radio-group v-model="ProjectNature">
                <el-radio border size="medium" style="width: 100%; margin-right: 0px" label="ALL"
                  >全部</el-radio
                >
                <div style="display: flex">
                  <span
                    style="border: 1px solid #dcdfe6; padding: 8px 0px 0px 5px"
                    v-if="dictProjectList[0] && dictProjectList[0][0]"
                  >
                    <el-radio size="medium" :label="dictProjectList[0][0].produceVal">{{
                      dictProjectList[0][0].produceName
                    }}</el-radio>
                    <div style="padding-left: 10px">
                      <el-checkbox-group v-model="produceList">
                        <template v-for="item in dictProjectList[0]">
                          <div :key="item.id">
                            <el-checkbox :label="item.dictVal">{{ item.dictName }}</el-checkbox>
                          </div>
                        </template>
                      </el-checkbox-group>
                    </div>
                  </span>
                  <span
                    style="border: 1px solid #dcdfe6; padding: 8px 0px 0px 5px"
                    v-if="dictProjectList[1] && dictProjectList[1][0]"
                  >
                    <el-radio size="medium" :label="dictProjectList[1][0].cooperateVal">{{
                      dictProjectList[1][0].cooperateName
                    }}</el-radio>
                    <div style="padding-left: 10px">
                      <el-checkbox-group v-model="cooperateList">
                        <template v-for="item in dictProjectList[1]">
                          <div :key="item.id">
                            <el-checkbox :label="item.dictVal">{{ item.dictName }}</el-checkbox>
                          </div>
                        </template>
                      </el-checkbox-group>
                    </div>
                  </span>

                  <span
                      style="border: 1px solid #dcdfe6; padding: 8px 0px 0px 5px"
                      v-if="dictProjectList[2] && dictProjectList[2][0]"
                    >
                      <el-radio size="medium" :label="dictProjectList[2][0].innerVal">{{
                        dictProjectList[2][0].innerName
                      }}</el-radio>
                      <div style="padding-left: 10px">
                        <el-checkbox-group v-model="innerList">
                          <template v-for="item in dictProjectList[2]">
                            <div :key="item.id">
                              <el-checkbox :label="item.dictVal">{{
                                item.dictName
                              }}</el-checkbox>
                            </div>
                          </template>
                        </el-checkbox-group>
                      </div>
                    </span>

                </div>
              </el-radio-group>
            </div> -->
          </div>
        </div>
      </div>
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-table
            ref="multipleTable"
            border
            :data="tableData"
            tooltip-effect="dark"
            height="string"
            row-key="id"
            lazy
            :load="load"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            @sort-change="sortChange"
            v-loading="loading"
          >
            <el-table-column
              align="center"
              fixed="left"
              prop="projectName"
              label="项目名称"
              :show-overflow-tooltip="false"
              min-width="320"
            ></el-table-column>

            <!-- <el-table-column prop="projectType" align="center" label="项目类型">
              <template slot-scope="scope">
                {{ scope.row.projectType | dict(dictData.projectType) }}
              </template>
            </el-table-column> -->
            <el-table-column prop="" width="120" align="center" label="项目性质">
              <template slot-scope="scope">
                {{ scope.row.projectSource | dict(dictData.projectNature) }}
              </template>
            </el-table-column>
            <el-table-column
              prop="projectSource"
              sortable
              width="120"
              align="center"
              label="项目来源"
            >
              <template slot-scope="scope">
                {{ scope.row.projectSource | dict(dictData.projectSource) }}
              </template>
            </el-table-column>
            <el-table-column
              prop="businessLines"
              sortable
              width="120"
              align="center"
              label="业务种类"
            >
              <template slot-scope="scope">
                {{ scope.row.businessLines | dict(dictData.businessLines) }}
              </template>
            </el-table-column>
            <el-table-column align="center" width="180" prop="projectStatus" label="项目状态">
              <template slot-scope="scope">
                {{ scope.row.projectStatus | dict(dictData.projectStatusList) }}
              </template>
            </el-table-column>
            <el-table-column align="center" width="180" prop="status" label="项目审批状态">
              <template slot-scope="scope">
                {{ scope.row.status | dict(dictData.projectStatus) }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              sortable
              width="130"
              prop="companyType"
              label="项目承接公司"
            >
              <template slot-scope="scope">
                {{ scope.row.companyType | dict(dictData.companyType) }}
              </template>
            </el-table-column>

            <!-- <el-table-column
              align="center"
              prop="agreementName"
              label="合同名称"
            ></el-table-column> -->
            <el-table-column
              align="center"
              :show-overflow-tooltip="false"
              min-width="220"
              prop="customersUnit"
              label="业主单位"
            ></el-table-column>
            <el-table-column
              align="center"
              width="130"
              sortable
              :show-overflow-tooltip="false"
              prop="predictContractAmount"
              label="我方合同金额"
            >
              <template slot-scope="scope">
                {{ scope.row.predictContractAmount | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              width="130"
              sortable
              :show-overflow-tooltip="false"
              prop="actualContractAmount"
              label="实际合同金额"
            >
              <template slot-scope="scope">
                {{ scope.row.actualContractAmount | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              width="130"
              sortable
              prop="projectBudget"
              label="预估成本"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="projectPredictStartDate"
              width="130"
              sortable
              label="预计开始时间"
            >
              <template slot-scope="scope">
                {{ scope.row.projectPredictStartDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="projectPredictEndDate"
              width="130"
              sortable
              label="预计结束时间"
            >
              <template slot-scope="scope">
                {{ scope.row.projectPredictEndDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="deptClassifyName" width="120" label="部门分类">
            </el-table-column>
            <el-table-column
              align="center"
              prop="deptClassifyManagerName"
              width="100"
              label="项目经理"
            >
            </el-table-column>

            <el-table-column label="操作" fixed="right" align="center" width="70">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  class="text_Details_Bgc"
                  @click.stop="edit(scope.row)"
                  >详情</el-button
                >
                <!-- <el-button
                  v-if="
                    scope.row.operatingButton.some((r) => {
                      return r == 'DELETE';
                    })
                  "
                  type="text"
                  size="small"
                  @click="del(scope)"
                  >删除</el-button
                > -->
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="parameter.total"
          >
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>
    <Edit
      v-if="showEditDialog"
      :isShow.sync="showEditDialog"
      :StautsType="StautsType"
      :ProjectType="ProjectType"
      :options="options"
      :mode="mode"
    ></Edit>
  </div>
</template>
<script>
import { initTreeData } from '@/util/common'
import { mapState } from 'vuex'
export default {
  components: {
    Edit: () => import('../Edit.vue'),
    Crumbs: () => import('@/components/Crumbs.vue'),
    DeptCascader: () => import('@/components/dept/DeptCascader.vue'),
  },
  data() {
    return {
      parameter: {
        pageNow: 1,
        pageSize: 50,
        total: 0,
        projectName: '',
        businessLines: '',
        projectSourceList: ['nei_bu_xiang_mu'],
        companyType: '',
        customersUnit: '',
        deptId: '',
        statusList: [],
      },
      pageSize: 0,
      dictData: {
        projectType: [],
        projectSource: [],
        projectStatus: [],
        businessLines: [],
        projectNature: [],
        companyType: [],
        projectStatusList: [],
      },
      projectNatureInfo: [],
      tableData: [],
      deptList: [],
      ProjectType: [],
      StautsType: [],
      loading: false,
      options: {},
      showEditDialog: false,
      customersUnit: [],
      projectList: [],
      produceList: [],
      cooperateList: [],
      innerList: [],
      projectStatus: [],
      ProjectNature: 'ALL',
      mode: 'edit',
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
    dictProjectList() {
      let arr = [[], [], []]
      this.projectNatureInfo.forEach(v => {
        this.dictData.projectSource.forEach(item => {
          if (v.dictVal.indexOf(item.dictVal) != -1 && v.dictName == '生产项目') {
            arr[0].push({
              id: item.id,
              produceName: v.dictName,
              produceVal: v.dictVal,
              dictVal: item.dictVal,
              dictName: item.dictName,
            })
          } else if (v.dictVal.indexOf(item.dictVal) != -1 && v.dictName == '合作项目') {
            arr[1].push({
              id: item.id,
              cooperateName: v.dictName,
              cooperateVal: v.dictVal,
              dictVal: item.dictVal,
              dictName: item.dictName,
            })
          } else if (v.dictVal.indexOf(item.dictVal) != -1 && v.dictName == '内部项目') {
            arr[2].push({
              id: item.id,
              innerName: v.dictName,
              innerVal: v.dictVal,
              dictVal: item.dictVal,
              dictName: item.dictName,
            })
          }
        })
      })
      return arr
    },
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.getData()
    this.getProject()
    this.getType()
  },
  provide() {
    return {
      refresh: this.refresh,
    }
  },
  filters: {
    applyAmount(n) {
      if (n == 0) {
        return n
      } else if (!n) {
        return
      } else {
        return Number(n).toLocaleString()
      }
    },
  },
  methods: {
    createProject(){
      this.mode = 'innerProject'
      this.options = {}
      this.showEditDialog = true
    },
    projectQuerySearch(queryString, cb) {
      let projectList = this.projectList.map(item => {
        return { value: item.projectName }
      })
      let results = queryString ? this.projectCreateFilter(queryString) : projectList
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    projectCreateFilter(queryString) {
      let results = this.projectList.map(item => {
        return { value: item.projectName }
      })
      // 将输入的内容进行拆分，返回值为一个数组
      const queryStringList = queryString.split('')
      if (queryStringList.length > 0) {
        queryStringList.forEach(queryItem => {
          let filterResults = results.filter(nameItem => nameItem.value?.includes(queryItem))
          results = [...filterResults]
        })
      }
      return results
    },
    onProkectNameSelect(e) {
      if (e) {
        this.parameter.projectName = e.value
      }
      this.$forceUpdate()
    },
    handleReset() {
      this.parameter.projectName = ''
      // this.parameter.projectType = ''
      this.parameter.customersUnit = ''
      this.parameter.deptId = ''
      this.parameter.businessLines = ''
      this.parameter.companyType = ''
      this.parameter.pageNow = 1
      this.ProjectNature = 'ALL'
      projectSourceList: ['nei_bu_xiang_mu'],
      this.cooperateList = []
      this.innerList = []
      this.produceList = []
      this.parameter.statusList = ['0', '5']
      this.getData()
    },
    getProject() {
      this.$api.project
        .listProject({ pageSize: 10000, statusList: ['0', '5'] })
        .then(res => {
          this.projectList = res.data.records
        })
        .catch(err => {
          console.log(err)
        })
    },
    dataUpdate(row) {
      this.$forceUpdate()
    },
    sortChange(column) {
      this.tableData = []
      if (column) {
        const orderMapping = {
          projectPredictStartDate: 'project_Predict_Start_Date',
          projectPredictEndDate: 'project_Predict_End_Date',
          // actualPredictStartDate: 'actual_predict_start_date',
          projectBudget: 'project_budget',
          actualContractAmount: 'actual_contract_amount',
          projectSource: 'project_source',
          businessLines: 'business_lines',
          companyType: 'company_type',
        }

        const orderRuleMapping = {
          ascending: 'ASC',
          descending: 'DESC',
        }

        if (orderMapping[column.prop] && orderRuleMapping[column.order]) {
          this.parameter.order = orderMapping[column.prop]
          this.parameter.orderRule = orderRuleMapping[column.order]

          // if (this.ProjectNature != 'ALL') {
          //   let arr = [...this.produceList, ...this.cooperateList, ...this.innerList]
          //   this.ProjectNature.forEach(v => {
          //     if (arr.indexOf(v) == -1) {
          //       arr.push(v)
          //     }
          //   })
          //   this.parameter.projectSourceList = [...arr]
          // } else {
          //   this.parameter.projectSourceList = [...this.produceList, ...this.cooperateList, ...this.innerList]
          // }
          this.parameter.projectSourceList = ['nei_bu_xiang_mu']
        } else if (column.order == null) {
          this.parameter.order = null
          this.parameter.orderRule = null
        }
        this.$api.project.listProject(this.parameter).then(res => {
          const arr = res.data.records.map(r => ({
            ...r,
            hasChildren: r.parentId === 0 && r.isHaveChild === true,
            children: [],
          }))

          this.tableData = arr
        })
      }
    },
    refresh() {
      this.showEditDialog = false
      this.pageChangeHandler(1)
    },
    // add() {
    //   this.options = {};
    //   this.showEditDialog = true;
    // },
    edit(row) {
      row.isRecord = true //项目操作记录显示
      this.options = row.deepClone()
      this.options.StartName = true
      this.mode = 'edit'
      this.showEditDialog = true
    },
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },
    getData() {
      this.loading = true

      // if (this.ProjectNature != 'ALL') {
      //   let arr = [...this.produceList, ...this.cooperateList, ...this.innerList]
      //   this.ProjectNature.forEach(v => {
      //     if (arr.indexOf(v) == -1) {
      //       arr.push(v)
      //     }
      //   })
      //   this.parameter.projectSourceList = [...arr]
      // } else {
      //   this.parameter.projectSourceList = [...this.produceList, ...this.cooperateList, ...this.innerList]
      // }
      this.parameter.projectSourceList = ['nei_bu_xiang_mu']
      this.$api.project
        .listProject(this.parameter)
        .then(res => {
          this.loading = false
          var arr = []

          res.data.records.some(r => {
            arr.push(
              r.combination({
                hasChildren: r.parentId == 0 && r.isHaveChild == true,
                children: [],
              })
            )
          })

          this.tableData = arr
          this.tableData.forEach(v => {
            if (this.customersUnit.indexOf(v.customersUnit) == -1) {
              this.customersUnit.push(v.customersUnit)
            }
          })
          this.parameter.total = res.data.total
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    load(tree, treeNode, resolve) {
      console.log(tree)
      console.log(treeNode)
      this.$api.project
        .listProjectNotPage({
          parentId: tree.id,
        })
        .then(res => {
          var arr = []
          res.data.some(r => {
            arr.push(
              r.combination({
                hasChildren: false,
              })
            )
          })
          resolve(arr)
        })
        .catch(err => {
          console.log(err)
        })
    },
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    // del(scope) {
    //   this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   })
    //     .then(() => {
    //       this.$api.project
    //         .del(scope.row.id)
    //         .then((res) => {
    //           this.$message.success("删除成功！");

    //           var hasLastPage =
    //             this.parameter.pageSize * this.parameter.pageNow >=
    //             this.parameter.total;
    //           var lastData = this.tableData.length < 2;
    //           if (lastData && hasLastPage && this.parameter.pageNow > 1) {
    //             this.parameter.pageNow--;
    //           }
    //           this.getData();
    //         })
    //         .catch((err) => {
    //           console.log(err);
    //         });
    //     })
    //     .catch(() => {
    //       //用户取消
    //     });
    // },
    getType() {
      // this.$api.dict
      //   .listSysDictData('PROJECT_TYPE', true)
      //   .then(res => {
      //     this.dictData.projectType = res.data
      //     // PROJECT_TYPE 项目类型
      //     this.ProjectType = initTreeData(res.data)
      //   })
      //   .catch(err => {
      //     console.log(err)
      //   })
      this.$api.dict
        .listSysDictData('PROJECT_NEW_STATUS', true)
        .then(res => {
          this.dictData.projectStatusList = res.data
          this.projectStatus = initTreeData(res.data)
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('PROJECT_NATURE', true)
        .then(res => {
          const arr = []
          res.data.forEach((v, i) => {
            if (v.dictVal) {
              let str = v.dictVal.split(',')
              arr.push({
                dictName: v.dictName,
                dictVal: str,
              })
              this.projectNatureInfo.push({
                id: i,
                dictName: v.dictName,
                dictVal: str,
              })
            }
          })

          arr.forEach((item, i) => {
            item.dictVal.forEach((value, index) => {
              this.dictData.projectNature.push({
                dictName: item.dictName,
                dictVal: value,
                id: Date.now() + ++index + ++i,
              })
            })
          })
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('COMPANY_TYPE', true)
        .then(res => {
          this.dictData.companyType = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('BUSINESS_LINES', true)
        .then(res => {
          this.dictData.businessLines = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('PROJECT_SOURCE', true)
        .then(res => {
          this.dictData.projectSource = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('PROJECT_STATUS', true)
        .then(res => {
          this.dictData.projectStatus = res.data
          // PROJECT_STATUS 项目状态
          this.StautsType = initTreeData(res.data)
        })
        .catch(err => {
          console.log(err)
        })

      this.$api.sysDept
        .getTreeDept()
        .then(res => {
          this.deptList = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
/deep/.el-checkbox {
  width: 102px;
  // border: 1px solid #dcdfe6;
  padding: 10px 20px 0 10px;
  border-radius: 4px;
  height: 36px;
  margin-right: 10px;
}
</style>
